<template>
  <div
    :class="{
      'justify-center w-full': collapsed,
    }"
    class="flex items-center gap-3 text-typography-disabled cursor-pointer"
    @click="toggleTheme"
  >
    <PulseLineMoon v-if="!isDarkMode" class="h-5 w-5" />
    <PulseLineSun v-else class="h-5 w-5" />
    <span v-if="!collapsed">{{ nextTheme }}</span>
  </div>
</template>

<script setup lang="ts">
import { useApplicationStore } from '~/store/application';

const store = useApplicationStore();
const currentTheme = computed(() => {
  return store.theme;
});

const nextTheme = computed(() => {
  return currentTheme.value === 'light' ? 'Dark' : 'Light';
});

const isDarkMode = computed(() => {
  return currentTheme.value === 'dark';
});

function toggleTheme() {
  store.toggleTheme();
}

const collapsed = inject('collapsed', true);
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
